/* tslint:disable */
import React from "react";
import Helmet from "react-helmet";

export interface METAProps {
  name?: string;
  content?: string | boolean;
  property?: undefined;
}

type SEOProps = {
  description?: string;
  lang?: string;
  meta?: METAProps[];
  keywords?: any;
  title: string;
  author?: string;
};

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  author,
}) => {
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | 美容皮膚科／土日祝診察`}
      meta={
        // <meta name="robots" content="noindex">

        process.env.GATSBY_ACTIVE_ENV !== "production"
          ? [
              {
                name: `robots`,
                content: `noindex`,
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: "google-site-verification",
                content: "6U1HzhKMJ6nItDZP1_ATcil-3b_aUEMnfz2E9Hw5CZo",
              },
              {
                property: `og:title`,
                content:
                  process.env.GATSBY_ACTIVE_ENV !== "production"
                    ? `${title}${String(process.env.GATSBY_ACTIVE_ENV)}`
                    : title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `al:web:should_fallback`,
                content: "",
              },
            ].concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords,
                  }
                : []
            )
          : [
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: "google-site-verification",
                content: "6U1HzhKMJ6nItDZP1_ATcil-3b_aUEMnfz2E9Hw5CZo",
              },
              {
                property: `og:title`,
                content:
                  process.env.GATSBY_ACTIVE_ENV !== "production"
                    ? `${title}${String(process.env.GATSBY_ACTIVE_ENV)}`
                    : title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `al:web:should_fallback`,
                content: "",
              },
            ].concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords,
                  }
                : []
            )
        // .concat(meta)
      }
      link={[
        { rel: "apple-touch-icon", href: "/img/apple-touch-icon.png" },
        {
          rel: "apple-touch-icon",
          sizes: "72x72",
          href: "/img/apple-touch-icon.png",
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  keywords: [],
  description: ``,
};

export default SEO;
