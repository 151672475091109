import React from "react";
import { StyledSystemDefaultProps } from "../../types/Common";

// types

interface P extends StyledSystemDefaultProps {
  onClick: () => void;
  text: string;
}

const CSButton: React.FC<P> = ({ onClick, text }) => {
  return (
    <div className="c-float-bottom-button" onClick={() => onClick()}>
      <div className="c-float-bottom-button__inner">
        <p className="c-float-bottom-button__text">
          無料カウンセリングを申し込む
        </p>
      </div>
    </div>
  );
};

export default React.memo(CSButton);
